import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Sivua ei löytynyt</h1>
    <p>Etsimääsi sivua ei valitettavasti löytynyt.</p>
    <p>
      Jos käytit kirjanmerkkiä tai palvelun ulkopuolista linkkiä, tarkistathan
      sivun osoitteen. Sivu on myös voitu poistaa käytöstä.
    </p>
  </Layout>
);

export default NotFoundPage;
